import { useState } from "react";
import CabAvatar from "@CabComponents/CabAvatar";
import { Box, styled, Typography, useMediaQuery } from "@mui/material";
import colors from "../../../../colors";
import { CabButton } from "@CabComponents/CabButton";
import { 
  BookingSlot, CreateUser, ExternalMeetingInfo, MeetingStatus, Organization, PublicLeader 
} from "../../../../store";
import { CabTextInput } from "@CabComponents/CabTextInput";
import { CabControlLabel } from "@CabComponents/CabControlLabel";
import { SidePanel } from "../../BookingSidePanel";
import { humanReadableDuration } from "../../../../utils/scheduleUtils";
import theme from "../../../../theme";
import { NAVY_LOGO_SRC } from "../../../../resourceUrls";
import CabSpinner from "@CabComponents/CabSpinner";


export enum MeetingCancelationStatus {
  INITIAL = 0,
  SUCCESS = 1,
  ERROR = 2,
  CANCELED = 3,
}

type Props = {
  cancelMeeting: (message: string) => void
  canceling: boolean
  cancelStatus: MeetingCancelationStatus
};

export interface MeetingInfoProps {
  meeting: ExternalMeetingInfo | null;
  dateSet: BookingSlot | null;
  currentTimeZone: string;
  leaders: PublicLeader[];
  confirmed: boolean;
  organization: Organization;
  name: string;
}

const StyledBox = styled(Box, {label: "StyledBox"})({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "24px 16px",
  width: "100%",
  border: `1px solid ${colors.black200}`
});

const TitleTypography = styled(Typography, {label: "TitleTypograpgy"})({
  fontSize: 24,
  fontWeight: "bold",
  color: colors.navyPrimary
});

const  MeetingCancelationConfirm = (
  {
    leaders, confirmed, canceling,
    cancelMeeting, cancelStatus, ...meetingInfoProps
  }: MeetingInfoProps & Props
) => {

  const user = meetingInfoProps.meeting?.create_user;
  const durationText = humanReadableDuration(meetingInfoProps.meeting?.duration_minutes || 0);
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const cancelationComponents = [
    <InitialCancelationView key="initial" cancelMeeting={cancelMeeting} canceling={canceling} leaders={leaders} />,
    <SuccessCancelationView key="success" />,
    <ErrorCancelationView key="error" user={user} />,
    <CanceledCancelationView key="canceled" />
  ];

  return <Box display="flex" flexDirection="column" justifyContent="center" sx={{width: "100%", height: "100%"}}>
    {isMdUp && (
      <SidePanel
        logo={meetingInfoProps.meeting?.create_user.active_license?.organization.logo || NAVY_LOGO_SRC}
        name={meetingInfoProps.meeting?.status.id === MeetingStatus.SCHEDULED && 
            meetingInfoProps.meeting.title_booked ? 
          meetingInfoProps.meeting.title_booked : meetingInfoProps.meeting?.title || ''}
        description={meetingInfoProps.meeting?.status.id === MeetingStatus.SCHEDULED && 
            meetingInfoProps.meeting.description_booked ? 
          meetingInfoProps.meeting.description_booked : meetingInfoProps.meeting?.description}
        durationText={durationText}
        leaders={leaders}
      />
    )}
    <Box width={'100%'} marginLeft={isMdUp ? '150px' : 0}>
      {cancelationComponents[cancelStatus]}
    </Box>
  </Box>;
};

export default MeetingCancelationConfirm;

type CancelationViewProps = {
  leaders: PublicLeader[]
  cancelMeeting: (message: string) => void,
  canceling: boolean
};

const InitialCancelationView = ({leaders, cancelMeeting, canceling}: CancelationViewProps) => {
  
  const [message, setMessage] = useState("");
  
  return <StyledBox>
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ maxWidth: 500 }}
    >
      <TitleTypography>
        Are you sure you want to cancel this meeting?
      </TitleTypography>
      <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
        {
          leaders.map((leader) => {
            const name = leader?.first_name + ' ' + leader?.last_name;
            return (
              <Box key={leader.id} display="flex" flexDirection="column" alignItems="center" padding="0px 8px">
                <CabAvatar
                  color={leader.color}
                  src={leader?.pic_url}
                  size="small"
                  name={`${leader.first_name} ${leader.last_name}`}
                />
                <Typography sx={{ paddingBottom: 1 }}>
                  {name}
                </Typography>
              </Box>
            );
          })
        }
      </Box>
      <Box>
        <Typography sx={{ marginTop: 2, marginBottom: 2 }}>
          This event will be removed from the calendar and attendees will be notified.
        </Typography>
      </Box>
      <Box sx={{width: "100%"}}>
        <CabControlLabel
          labelPlacement="top"
          sx={{
            marginBottom: 3,
            width: "100%",
            textAlign: "left",
            alignItems: "start",
            "& .MuiFormControl-root": {
              width: "100%",
            },
            "& .MuiTypography-root": {
              fontWeight: "bold",
              marginBottom: 1
            }
          }}
          label="Send a message to the organizer (optional)"
          control={<CabTextInput
            multiline
            sx={{width: "100%"}}
            minRows={3}
            maxRows={6}
            value={message}
            onChange={(e) => setMessage(e.currentTarget.value)}
          />}
        />
      </Box>
      <CabButton onClick={() => cancelMeeting(message)} sx={{ marginBottom: 3, width: 150, height: 32 }}>
        {canceling ? <CabSpinner scale={0.7} sx={{color: colors.white900}} /> : "Cancel Meeting"}
      </CabButton>
    </Box>
  </StyledBox>;
};

const SuccessCancelationView = () => {
  return <StyledBox>
    <TitleTypography>Meeting was successfully canceled!</TitleTypography>
  </StyledBox>;
};

const CanceledCancelationView = () => {
  return <StyledBox>
    <TitleTypography>This meeting has already been canceled.</TitleTypography>
  </StyledBox>;
};

const ErrorCancelationView = ({user}: {user?: CreateUser}) => {
  return <StyledBox flexDirection={"column"}>
    <TitleTypography sx={{marginBottom: 3}}>Sorry, this meeting could not be canceled.</TitleTypography>
    <Typography>Contact {user?.first_name} {user?.last_name} ({user?.email}) to cancel this meeting. </Typography>
  </StyledBox>;
};
