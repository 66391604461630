import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import queryString from 'query-string';
import {
  actions, BookingSlot, BookingSlotsByDay, ExternalMeetingInfo, FetchReturn, MeetingQuestionAnswer,
  MeetingQuestionAnswerSubmission, MeetingStatus, NormalizedExternalParticipant, RootState, ThunkDispatchType
} from "../../store";
import { DateTime } from "luxon";
import { getLocalTimeZoneName } from "../../utils/scheduleUtils";
import BookMeeting from "./BookMeeting";
import { trackEvent } from "../../utils/appAnalyticsUtils";
import { EVENT_TYPE } from "../../constants";
import { successConfetti } from "../../utils/renderUtils";
import { CabButton } from "@CabComponents/CabButton";
import { CabModal } from "@CabComponents/CabModal";
import { useMountEffect } from "../../utils/hooks";
import { isMobile } from "../../utils/screenSizeUtils";

export enum MeetingBookingStep {
  DATE_SELECTION = 0,
  MEETING_INFO_STEP = 1,
  MEETING_CONFIRMATION = 2,
  NO_TIMES_WORK = 3
}

const orderTimeSlots = (a: number, b: number) => a - b;

interface ComponentProps {
  externalId?: string,
  isPreview?: boolean,
  prefillName?: string;
  prefillEmail?: string;
  isFramed?: boolean;
  noCabPage?: boolean;
  isEnterpriseBook?: boolean;
  setBookingComplete?: (data?: {isIntroProcessComplete: boolean, shouldUpdateOrg: boolean}) => void;
}

type RouteParams = { meetingId: string, meetingToken: string };

type Props = ComponentProps;

const BookMeetingContainer = ({
  externalId, isPreview, prefillEmail, prefillName, setBookingComplete, isFramed, noCabPage, isEnterpriseBook
}: Props): ReactElement => {
  const match = useParams<RouteParams>();
  const organization = useSelector((state: RootState) => state.organization);
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const dispatch = useDispatch<ThunkDispatchType>();
  const location = useLocation();

  const { start: urlStart, end: urlEnd, framed } = queryString.parse(location.search, { parseBooleans: true }) as {
    start?: string; end?: string; framed?: boolean;
  };

  const [currentTimeZone, setCurrentTimeZone] = useState<string>(getLocalTimeZoneName());

  // make sure we validate url parameters
  const urlStartDateTime = useMemo(() => {
    let start = urlStart ? DateTime.fromISO(urlStart, { zone: currentTimeZone }) : undefined;
    if (start && !start.isValid) {
      start = undefined;
    }
    return start;
  }, [urlStart, currentTimeZone]);

  const urlEndDateTime = useMemo(() => {
    let end = urlEnd ? DateTime.fromISO(urlEnd, { zone: currentTimeZone }) : undefined;
    if (end && !end.isValid) {
      end = undefined;
    }
    return end;
  }, [urlEnd, currentTimeZone]);

  const [meetingInfo, setMeetingInfo] = useState<ExternalMeetingInfo | null>(null);
  const [step, setStep] = useState<MeetingBookingStep>(MeetingBookingStep.DATE_SELECTION);
  const [selectedDay, setSelectedDay] = useState(urlStartDateTime || null);
  const [slotSelected, setSlotSelected] = useState<BookingSlot | null>(null);
  const [slotsByDay, setSlotsByDay] = useState<BookingSlotsByDay>({});
  const [isBooking, setIsBooking] = useState(false);
  const [allowAddParticipants, setAllowAddParticipants] = useState(true);
  const [confirmed, setConfirmed] = useState(false);
  const [isBadResponse, setIsBadResponse] = useState(false);
  const [isSelectedDay, setIsSelectedDay] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingCalendar, setLoadingCalendar] = useState(false);
  const [defaultYearMonth, setDefaultYearMonth] = useState<[number, number] | null>(null);
  const [rebookParticipants, setRebookParticipants] = useState<NormalizedExternalParticipant[]>([]);
  const [rebookAnswers, setRebookAnswers] = useState<MeetingQuestionAnswerSubmission[]>([]);
  const [showConflictModal, setShowConflictModal] = useState(false);
  const [viewDate, setViewDate] = useState<DateTime>(DateTime.now());
  const [noTimesWork, setNoTimesWork] = useState(false);
  const [noTimesWorkSent, setNoTimesWorkSent] = useState(false);

  const navigate = useNavigate();

  const isReschedulingURL = location.pathname.includes("reschedule");
  const isCancelingURL = location.pathname.includes("cancel");
  const orderedAvailableDates = useMemo(() => (
    Object.keys((slotsByDay))
      .map(s => Number(s))
      .sort(orderTimeSlots)
      .map(s => DateTime.fromMillis(s, { zone: currentTimeZone }))
  ), [slotsByDay, currentTimeZone]);

  useEffect(() => {
    if (meetingInfo && meetingInfo.is_poll) {
      const path = location.pathname.replace("book", "poll");
      navigate(path);
    }
  }, [navigate, meetingInfo, location.pathname]);

  useEffect(() => {
    switch (step) {
      case MeetingBookingStep.DATE_SELECTION:
        if (noTimesWork) {
          setStep(MeetingBookingStep.NO_TIMES_WORK);
        }
        break;
    }
  }, [noTimesWork, step]);


  useEffect(() => {
    if (noTimesWorkSent && setBookingComplete) {
      setBookingComplete({isIntroProcessComplete: true, shouldUpdateOrg: !noTimesWorkSent});
    }
  }, [noTimesWorkSent, setBookingComplete]);

  const onCalendarViewChange = useCallback(async (
    date: DateTime, timezone?: string
  ): Promise<[
    ExternalMeetingInfo | Record<never, never>, DateTime | null, BookingSlot[], { start: DateTime; end: DateTime }
  ] | undefined> => {
    const tz = timezone || currentTimeZone;
    const curDate = date.setZone(tz);
    setViewDate(curDate);
    const slotsStart = curDate.set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
    const slotsEnd = slotsStart.endOf('month');

    const { meetingId, meetingToken } = match;
    const meetingExternalId = externalId || meetingId;
    if (!meetingExternalId) return;

    setLoadingCalendar(true);

    const res = await dispatch(actions.schedule.fetchMeetingExternal(
      meetingExternalId, meetingToken, slotsStart, slotsEnd,
    ));

    let firstAvailableDate: DateTime | null = null;
    let bookingSlots: BookingSlot[] = [];

    if ('status' in res) {
      // verify slots really are in the requested time interval
      bookingSlots = res.booking_slots
        .map(s => ({
          ...s,
          start: DateTime.fromISO(s.start).setZone(tz).toISO() || "",
          end: DateTime.fromISO(s.end).setZone(tz).toISO() || "",
        }))
        .filter(s => (
          DateTime.fromISO(s.start).toMillis() >= slotsStart.toMillis()
          && DateTime.fromISO(s.start).toMillis() <= slotsEnd.toMillis()
        ));

      const curSlotsByDay = timeSlotsByDay(bookingSlots, tz);
      firstAvailableDate = Object.keys((curSlotsByDay))
        .map(s => Number(s))
        .sort()
        .map(s => DateTime.fromMillis(s, { zone: tz }))
        .at(0) || null;

      let newSelectedDay: DateTime | null = null;

      if (bookingSlots.find(s => DateTime.fromISO(s.start).toMillis() === curDate.toMillis())) {
        newSelectedDay = curDate;
      } else {
        newSelectedDay = firstAvailableDate;
      }

      // this is for mobile
      setIsSelectedDay(true);

      setSlotsByDay(curSlotsByDay);
      setSelectedDay(newSelectedDay || date);
    }

    setLoadingCalendar(false);

    return [res, firstAvailableDate, bookingSlots, { start: slotsStart, end: slotsEnd }];
  }, [dispatch, externalId, match, currentTimeZone]);

  const handleLoadInitialTimes = useCallback(async () => {
    setLoading(true);
    const date = urlStartDateTime || DateTime.now();

    const onChangeInitialRes = await onCalendarViewChange(date);
    if (onChangeInitialRes) {
      let [res, firstAvailableDate, slots, range] = onChangeInitialRes;
      if (!('status' in res)) {
        setLoading(false);
        return;
      }

      const scheduledDay = res.status.id === MeetingStatus.PENDING && res.event_start_time
        ? DateTime.fromISO(res.event_start_time)
        : null;

      // need to check whether scheduledDay is actually in the currently fetched dates. If not, fetch again.
      if (scheduledDay && (scheduledDay < range.start || scheduledDay > range.end)) {
        const onChangeResScheduleDayOutofRange = await onCalendarViewChange(scheduledDay);
        if (onChangeResScheduleDayOutofRange) {
          [res, firstAvailableDate, slots, range] = onChangeResScheduleDayOutofRange;
        }
      } else if (!firstAvailableDate) {
        let earliestDate: DateTime | undefined = undefined;
        if (('status' in res)) {
          earliestDate = res.earliest_slot_date ? DateTime.fromISO(res.earliest_slot_date) : undefined;
        }
        const nextMonthDate = earliestDate && earliestDate > date.plus({ month: 1 }) 
          ? earliestDate : date.plus({ month: 1 });
        const onChangeResFalseyFirstAvailableDate = await onCalendarViewChange(nextMonthDate);
        if (onChangeResFalseyFirstAvailableDate) {
          [res, firstAvailableDate, slots, range] = onChangeResFalseyFirstAvailableDate;
        }

        // if we still don't find anything in the next month, reset the selected day
        if (!firstAvailableDate) {
          setSelectedDay(date);
        }
      }

      if (firstAvailableDate && date && slots?.length) {
        const autoSelectSlot = slots.find(s => (
          DateTime.fromISO(s.start).toMillis() === date.toMillis()
          && (!urlEndDateTime || DateTime.fromISO(s.end).toMillis() === urlEndDateTime.toMillis())
        ));
        if (autoSelectSlot) {
          setSlotSelected(autoSelectSlot);
        }
      }
      const calViewDate = firstAvailableDate || date;

      if (!('status' in res)) {
        setLoading(false);
        return;
      }

      if (isMobile() && !slotSelected) {
        setIsSelectedDay(false);
      }

      setMeetingInfo(res);
      setDefaultYearMonth([calViewDate.year, calViewDate.month]);
      setViewDate(calViewDate);
      setShowConflictModal(false);
      setAllowAddParticipants(res.allow_add_participants !== undefined ? res.allow_add_participants : true);

      if (res.status.id !== 1 || !res.use_link) {
        if (isReschedulingURL) {
          setStep(MeetingBookingStep.DATE_SELECTION);
        } else {
          setStep(MeetingBookingStep.MEETING_CONFIRMATION);
        }
      } else {
        setStep(MeetingBookingStep.DATE_SELECTION);
      }
    }

    setLoading(false);

  }, [onCalendarViewChange, urlStartDateTime, urlEndDateTime, isReschedulingURL, slotSelected]);

  // find the first available slot (if any) so we can set the default calendar view to that month
  useMountEffect(() => {
    handleLoadInitialTimes();
  });

  const handleFetchOrg = useCallback(async () => {
    if (!isEnterpriseBook) {
      if (meetingInfo?.create_user.id) {
        await dispatch(actions.organization.fetchPublicOrganizationByUser(meetingInfo.create_user.id));
      }
    }
  }, [dispatch, meetingInfo?.create_user, isEnterpriseBook]);

  useEffect(() => {
    handleFetchOrg();
  }, [handleFetchOrg]);

  const timeSlotsByDay = (slots: BookingSlot[], timezoneName: string) => {
    const eventsByDay: {
      [date: number]: Array<BookingSlot>
    } = {};

    slots.forEach(slot => {
      const day = DateTime.fromISO(slot.start).setZone(timezoneName).startOf('day').toMillis();
      eventsByDay[day] = eventsByDay[day] ? [...eventsByDay[day], slot] : [slot];
    });
    return eventsByDay;
  };

  const handleScheduleMeeting = (externalParticipants: NormalizedExternalParticipant[],
    meetingQuestionAnswers?: MeetingQuestionAnswerSubmission[]) => {
    const meetingToken = match.meetingToken;
    const meetingId = match.meetingId || externalId;
    //If there is no meeting token the component is being rendered in preview mode 
    //Therefore the request shouldn't be made
    if (meetingId && slotSelected) {
      setIsBooking(true);
      const bookingResponse = (res: FetchReturn) => {
        if (res.status === 400 && res.data.code === 'slot_not_available') {
          setIsBooking(false);
          setRebookParticipants(externalParticipants);
          setRebookAnswers(meetingQuestionAnswers || []);
          setShowConflictModal(true);
        } else if (res.status !== 200) {
          setIsBadResponse(true);
          setStep(MeetingBookingStep.MEETING_CONFIRMATION);
        } else {
          setConfirmed(true);
          if (setBookingComplete) {
            setBookingComplete({isIntroProcessComplete: true, shouldUpdateOrg: !noTimesWorkSent});
          }
          setIsBooking(false);
          setStep(MeetingBookingStep.MEETING_CONFIRMATION);
          setMeetingInfo(res.data);
          if (res.data.event_id) {
            successConfetti();
          }
          trackEvent(EVENT_TYPE.MEETING_BOOKED);
        }
      };

      if (isReschedulingURL && meetingToken) {
        dispatch(actions.schedule.bookExternalReschedule(
          meetingId,
          meetingToken,
          externalParticipants,
          slotSelected.start,
          meetingQuestionAnswers
        )).then(bookingResponse);
      } else {
        if (meetingInfo) {
          dispatch(actions.schedule.bookExternal(
            meetingId,
            externalParticipants,
            slotSelected.start,
            organization.id,
            meetingInfo?.create_user.id,
            organization.name,
            meetingQuestionAnswers
          )).then(bookingResponse);
        }
      }
    } else {
      setStep(MeetingBookingStep.MEETING_CONFIRMATION);
    }
  };

  const fetchMeetingQuestionAnswers = useCallback(
    async (externalMeetingId?: string): Promise<{ answers: { [id: number]: MeetingQuestionAnswer } } | undefined> => {
      const response = await dispatch(actions.schedule.fetchExternalMeetingAnswers(
        externalMeetingId ?? meetingInfo?.external_id ?? ""));
      if (response?.status === 200) {
        return response.data;
      }
    }, [dispatch, meetingInfo?.external_id]);

  const handleCancelMeeting = async (message: string) => {
    if (meetingInfo && match.meetingToken) {
      return await dispatch(actions.schedule.cancelExternalMeeting(meetingInfo.id, match.meetingToken, message));
    }
  };

  const handleUpdateTimezone = (tz: string) => {
    setCurrentTimeZone(tz);
    onCalendarViewChange(viewDate.setZone(tz), tz);
    // setSelectedDay(null);
  };

  const submitNoTimesWorkForm = async (value: NormalizedExternalParticipant) => {
    const res = await dispatch(actions.schedule.sendNoTimesWork(value));
    if (res) {
      setNoTimesWorkSent(true);
    }
    setStep(MeetingBookingStep.MEETING_CONFIRMATION);
  };

  return (
    <>
      <BookMeeting
        step={step}
        setNoTimesWork={setNoTimesWork}
        meetingInfo={meetingInfo}
        isAuthenticated={isAuthenticated}
        selectedDay={selectedDay}
        orderedAvailableDates={orderedAvailableDates}
        slotsByDay={slotsByDay}
        currentTimezone={currentTimeZone}
        slotSelected={slotSelected}
        isBooking={isBooking}
        setCurrentTimeZone={handleUpdateTimezone}
        handleDaySelected={d => setSelectedDay(d)}
        setSlotSelected={setSlotSelected}
        onConfirm={() => setStep(step + 1)}
        setStep={setStep}
        handleScheduleMeeting={handleScheduleMeeting}
        fetchMeetingQuestionAnswers={fetchMeetingQuestionAnswers}
        isReschedulingURL={isReschedulingURL}
        isCancelingURL={isCancelingURL}
        organization={organization}
        handleCancelMeeting={handleCancelMeeting}
        allowAddParticipants={allowAddParticipants}
        confirmed={confirmed}
        isPreview={isPreview}
        isBadResponse={isBadResponse}
        isSelectedDay={isSelectedDay}
        prefillEmail={prefillEmail}
        prefillName={prefillName}
        isFramed={framed ?? isFramed}
        onCalendarDateChange={onCalendarViewChange}
        loading={loading}
        defaultYearMonth={defaultYearMonth}
        rebookParticipants={rebookParticipants}
        rebookAnswers={rebookAnswers}
        viewDate={viewDate}
        noCabPage={noCabPage}
        isEnterpriseBook={isEnterpriseBook}
        submitNoTimesWorkForm={submitNoTimesWorkForm}
        noTimesWork={noTimesWork}
        noTimesWorkSent={noTimesWorkSent}
        loadingCalendar={loadingCalendar}
      />
      {showConflictModal && (
        <CabModal
          open={showConflictModal}
          onClose={() => setShowConflictModal(false)}
          title={`Time Not Available`}
          text="Sorry, the time you selected is no longer available. Please select a new time to continue."
          isAlert={true}
          noFullScreen={true}
          actionButtons={
            <>
              <CabButton buttonType='primary' color='primary' disabled={loading} onClick={handleLoadInitialTimes}>
                Select a new time
              </CabButton>
            </>
          }
        />
      )}
    </>
  );
};

export const Meeting = BookMeetingContainer;

export default BookMeetingContainer;
